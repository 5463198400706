import * as React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import Loader from "./components/Loader";

const Landing = React.lazy(() => import("./screens/Landing"));
const About = React.lazy(() => import("./screens/About"));
const Experience = React.lazy(() => import("./screens/Experience"));
const Blogs = React.lazy(() => import("./screens/Blogs"));
const ReadBlog = React.lazy(() => import("./screens/ReadBlog"));
const WorkDetails = React.lazy(() => import("./screens/WorkDetails"));

const theme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'Poppins'].join(","),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <React.Suspense fallback={<Loader />}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/experience" element={<Experience />} />
            {/* <Route exact path="/blogs" element={<Blogs />} />
            <Route path="/read" element={<ReadBlog />} /> */}
            <Route path="/experience-dets" element={<WorkDetails />} />
          </Routes>
        </Router>
      </React.Suspense>
    </ThemeProvider>
  );
}

export default App;
