import * as React from 'react';
import logo from '../img/logo.png';
import { Typography } from '@mui/material';

export default function Loader(){
    return(
        <>
        <div
        style={{ 
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
         }}>
            <img src={logo} alt='Site Logo' height='50%'/>
            <Typography variant='h6'>Srivats Venkataraman</Typography>
        </div>
        </>
    );
}
